import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { FixturesService } from '../../../services/fixtures.service';
import { RacesService } from '../../../services/races.service';
import { EntriesService } from '../../../services/entries.service';
import { ModalActionsService } from '../../../services/modalActions.service';
var FixtureReportComponent = /** @class */ (function () {
    function FixtureReportComponent(notification, route, eventsService, fixturesService, racesService, entriesService, modalActionsService, modalService) {
        var _this = this;
        this.notification = notification;
        this.route = route;
        this.eventsService = eventsService;
        this.fixturesService = fixturesService;
        this.racesService = racesService;
        this.entriesService = entriesService;
        this.modalActionsService = modalActionsService;
        this.modalService = modalService;
        this.getFixtureInfo = function (fixtureYear, fixtureId) {
            _this.meta.status = 'Loading Fixture Info';
            forkJoin(_this.fixturesService.getFixtureInfo({ fixtureYear: fixtureYear, fixtureId: fixtureId }), _this.fixturesService.getFixtureComments({ fixtureYear: fixtureYear, fixtureId: fixtureId })).subscribe(function (res) {
                _this.fixture = res[0];
                _this.fixture.fixtureYear = _this.fixture.year;
                _this.eventsService.changeFixtureData(_this.fixture);
                _this.fixture.scheduledOfficials = groupBy(_this.fixture.scheduledOfficials, 'role');
                _this.fixture.comments = res[1]['data'];
                _this.getFixtureRaces(_this.fixture.fixtureYear, _this.fixture.fixtureId);
            }, function (err) {
                _this.notification.error("Could't get the Fixture information: " + _this.eventsService.getApiErrorMessage(err), 'Error!');
                _this.meta.loading = false;
            });
        };
        this.getFixtureRaces = function (fixtureYear, fixtureId) {
            _this.meta.status = 'Loading Fixture\'s Races';
            _this.fixturesService.getFixtureRaces({ fixtureYear: fixtureYear, fixtureId: fixtureId }).subscribe(function (res) {
                _this.races = res;
                forkJoin(_this.getRaceInfo(_this.races), _this.getRaceEntries(_this.races), _this.getRaceComments(_this.races), _this.getRaceEnquiries(_this.races)).subscribe(function () { return _this.processRaceInfo(); }, function (err) { return console.log(err); });
            }, function (err) {
                _this.notification.error("Could't get the Fixture Entries with Sampling Requests: " + _this.eventsService.getApiErrorMessage(err), 'Error!');
                _this.meta.loading = false;
            });
        };
        this.getRaceInfo = function (races) {
            return new Promise(function (resolve, reject) {
                var raceInfoRequests = races.map(function (race) { return _this.racesService.getRaceInfo(race); });
                forkJoin.apply(void 0, raceInfoRequests).subscribe(function (results) {
                    results.forEach(function (raceInfo, index) {
                        if (!raceInfo || !raceInfo['fixtureId']) {
                            console.log('Failed to load data:', races[index].raceId, races[index]);
                            _this.notification.error("Could't get race info for the " + moment(races[index].raceDateTime).format('HH:mm') + " race.", 'Error!');
                            _this.races[index].info = {};
                        }
                        else {
                            _this.races[index].info = raceInfo;
                        }
                    });
                    resolve();
                }, function (errors) {
                    console.log('Failed to load data:', errors);
                    _this.notification.error("Could't get info for races.", 'Error!');
                    _this.meta.loading = false;
                    reject(errors);
                });
            });
        };
        this.getRaceEntries = function (races) {
            return new Promise(function (resolve, reject) {
                var racesCompleted = 0;
                var entriesRequests = races.map(function (race) { return _this.racesService.getRaceEntries(race); });
                forkJoin.apply(void 0, entriesRequests).subscribe(function (results) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                results.forEach(function (entries, indexRace) {
                                    if (!entries || !entries[0]) {
                                        console.log('Failed to load data:', races[indexRace].raceId, races[indexRace]);
                                        _this.notification.error("Could't get some details for the " +
                                            (moment(races[indexRace].raceDateTime).format('HH:mm') + " race."), 'Error!');
                                        _this.races[indexRace].entries = [];
                                    }
                                    else {
                                        _this.races[indexRace].entries = entries;
                                    }
                                });
                                return [4 /*yield*/, this.getEntriesComments(this.races)];
                            case 1:
                                _a.sent();
                                resolve();
                                return [2 /*return*/];
                        }
                    });
                }); }, function (errors) {
                    console.log('Failed to load data:', errors);
                    _this.notification.error("Could't get some details for racecards.", 'Error!');
                    _this.meta.loading = false;
                    reject(errors);
                });
            });
        };
        this.getRaceComments = function (races) {
            return new Promise(function (resolve, reject) {
                var raceCommentsRequests = races.map(function (race) { return _this.racesService.getRaceComments(race); });
                forkJoin.apply(void 0, raceCommentsRequests).subscribe(function (results) {
                    results.forEach(function (raceComments, index) {
                        if (!raceComments['data']) {
                            console.log('Failed to load data:', races[index].raceId, races[index]);
                            _this.notification.error("Could't get race comments for the " +
                                (moment(races[index].raceDateTime).format('HH:mm') + " race."), 'Error!');
                            _this.races[index].comments = [];
                        }
                        else {
                            _this.races[index].comments = raceComments['data'];
                        }
                    });
                    resolve();
                }, function (errors) {
                    console.log('Failed to load data:', errors);
                    _this.notification.error("Could't get comments for races.", 'Error!');
                    _this.meta.loading = false;
                    reject(errors);
                });
            });
        };
        this.getEntriesComments = function (races) {
            _this.meta.status = 'Loading Horses Comments';
            var horsesCommentsRequests = [];
            return new Promise(function (resolve, reject) {
                races.forEach(function (race, raceIndex) {
                    race.horsesComments = [];
                    horsesCommentsRequests = horsesCommentsRequests
                        .concat(race.entries
                        .map(function (entry) { return _this.entriesService
                        .getEntryTodayComments(entry.horseId, false, moment(entry.raceDateTime).format('YYYYMMDD'), moment(entry.raceDateTime).format('YYYYMMDD')); }));
                });
                forkJoin.apply(void 0, horsesCommentsRequests).subscribe(function (results) {
                    results.forEach(function (comments, index) {
                        if (!comments['data']) {
                            console.log('Failed to load data:', races[index].raceId, races[index]);
                            _this.notification.error("Could't get horses comments.", 'Error!');
                            _this.races[index].horsesComments = [];
                        }
                        else {
                            comments['data'].forEach(function (comment) {
                                var raceIndex = _this.races.findIndex(function (r) { return r.raceId === comment.raceId && r.divisionSequence === comment.divisionSequence; });
                                if (raceIndex === -1) {
                                    console.log('[ DEBUG ] RACE NOT FOUND', 'comment data', comment, 'current fixture', _this.fixture.fixtureId, 'fixture\'s races:', _this.races);
                                }
                                // Weirdly enough, there could be horses running in different fixtures on the same day apparently
                                if (raceIndex > -1) {
                                    _this.races[raceIndex].horsesComments.push(comment);
                                }
                            });
                        }
                    });
                    resolve();
                }, function (errors) {
                    console.log('Failed to load data:', errors);
                    _this.notification.error("Could't get horses comments for one or more races.", 'Error!');
                    _this.meta.loading = false;
                    reject(errors);
                });
            });
        };
        this.getRaceEnquiries = function (races) {
            return new Promise(function (resolve, reject) {
                var raceEnquiriesRequests = races.map(function (race) { return _this.racesService.getRaceEnquiries(race); });
                forkJoin.apply(void 0, raceEnquiriesRequests).subscribe(function (results) {
                    results.forEach(function (raceEnquiries, index) {
                        if (!raceEnquiries['data']) {
                            console.log('Failed to load data:', races[index].raceId, races[index]);
                            _this.notification.error("Could't get race enquiries for the " +
                                (moment(races[index].raceDateTime).format('HH:mm') + " race."), 'Error!');
                            _this.races[index].enquiries = [];
                        }
                        else {
                            raceEnquiries['data'].forEach(function (enquiry) {
                                var breachStatus = enquiry.outcomes.find(function (eo) { return eo.objectType === 'inBreach'; });
                                if (breachStatus !== undefined && breachStatus.isApplied) {
                                    enquiry.breachStatus = breachStatus.value.replace(/_/g, ' ');
                                }
                                else {
                                    enquiry.breachStatus = 'not in breach';
                                }
                            });
                            _this.races[index].enquiries = raceEnquiries['data'];
                        }
                    });
                    resolve();
                }, function (errors) {
                    console.log('Failed to load data:', errors);
                    _this.notification.error("Could't get enquiries for races.", 'Error!');
                    _this.meta.loading = false;
                    reject(errors);
                });
            });
        };
        this.processRaceInfo = function () {
            _this.meta.status = 'Processing...';
            _this.races.forEach(function (race, raceIndex) {
                race.nonRunners = race.entries.filter(function (e) { return e.nonRunnerDeclaredDate; });
                race.withdrawals = race.entries.filter(function (e) { return e.indicators.isWithdrawn; });
                race.jockeyChanged = race.entries.filter(function (e) { return e.indicators.hasJockeyChange; });
                race.selectedSampling = race.entries.filter(function (e) { return e.indicators.hasBeenSelectedForSampling; });
            });
            _this.getAdditionalInfo();
        };
        this.getAdditionalInfo = function () {
            _this.meta.status = 'Loading additional info';
            forkJoin(_this.getAdditionalInfoNonRunners(), _this.getAdditionalInfoWithdrawals(), _this.getAdditionalInfoJockeyChanged(), _this.getAdditionalInfoSelectedSampling()).subscribe(function (res) {
                console.log('[ DEBUG ] Finished', 'Fixture', _this.fixture, 'Races/Entries', _this.races);
                _this.meta.status = 'Finalising...';
                setTimeout(function () { return _this.meta.loading = false; }, 1000);
            }, function (err) { return console.log(err); });
        };
        this.getAdditionalInfoNonRunners = function () {
            return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var racesCompleted, _i, _a, race, _b, _c, nr, res;
                return tslib_1.__generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            racesCompleted = 0;
                            _i = 0, _a = this.races;
                            _d.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 7];
                            race = _a[_i];
                            if (!race.nonRunners.length) return [3 /*break*/, 5];
                            _b = 0, _c = race.nonRunners;
                            _d.label = 2;
                        case 2:
                            if (!(_b < _c.length)) return [3 /*break*/, 5];
                            nr = _c[_b];
                            return [4 /*yield*/, execRequest(this.modalActionsService.getNonRunnerInfo(nr))];
                        case 3:
                            res = _d.sent();
                            nr.stipesReason = res.reasonText;
                            nr.stipesComment = res.comment;
                            nr.stipesInternalComment = res.internalComment;
                            _d.label = 4;
                        case 4:
                            _b++;
                            return [3 /*break*/, 2];
                        case 5:
                            racesCompleted++;
                            _d.label = 6;
                        case 6:
                            _i++;
                            return [3 /*break*/, 1];
                        case 7:
                            if (racesCompleted === this.races.length) {
                                resolve();
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        this.getAdditionalInfoWithdrawals = function () {
            return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var racesCompleted, _i, _a, race, _b, _c, w, res;
                return tslib_1.__generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            racesCompleted = 0;
                            _i = 0, _a = this.races;
                            _d.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 7];
                            race = _a[_i];
                            if (!race.withdrawals.length) return [3 /*break*/, 5];
                            _b = 0, _c = race.withdrawals;
                            _d.label = 2;
                        case 2:
                            if (!(_b < _c.length)) return [3 /*break*/, 5];
                            w = _c[_b];
                            return [4 /*yield*/, execRequest(this.modalActionsService.getWithdrawnInfo(w))];
                        case 3:
                            res = _d.sent();
                            w.feePaid = res.feePaid;
                            w.stipesReason = res.reasonText;
                            w.stipesComment = res.comment;
                            w.stipesInternalComment = res.stipesInternalComment;
                            _d.label = 4;
                        case 4:
                            _b++;
                            return [3 /*break*/, 2];
                        case 5:
                            racesCompleted++;
                            _d.label = 6;
                        case 6:
                            _i++;
                            return [3 /*break*/, 1];
                        case 7:
                            if (racesCompleted === this.races.length) {
                                resolve();
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        this.getAdditionalInfoJockeyChanged = function () {
            return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var racesCompleted, _i, _a, race, _b, _c, jockey, latestChange;
                return tslib_1.__generator(this, function (_d) {
                    racesCompleted = 0;
                    for (_i = 0, _a = this.races; _i < _a.length; _i++) {
                        race = _a[_i];
                        if (race.jockeyChanged.length) {
                            for (_b = 0, _c = race.jockeyChanged; _b < _c.length; _b++) {
                                jockey = _c[_b];
                                latestChange = jockey.jockeyChangeHistory[jockey.jockeyChangeHistory.length - 1];
                                jockey.oldJockeyName = latestChange.oldJockeyKnownAs;
                                jockey.newJockeyName = latestChange.newJockeyKnownAs;
                                jockey.stipesReason = latestChange.comment;
                            }
                        }
                        racesCompleted++;
                    }
                    if (racesCompleted === this.races.length) {
                        resolve();
                    }
                    return [2 /*return*/];
                });
            }); });
        };
        this.getAdditionalInfoSelectedSampling = function () {
            return new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var racesCompleted, _i, _a, race, _b, _c, ss, res;
                return tslib_1.__generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            racesCompleted = 0;
                            _i = 0, _a = this.races;
                            _d.label = 1;
                        case 1:
                            if (!(_i < _a.length)) return [3 /*break*/, 7];
                            race = _a[_i];
                            if (!race.selectedSampling.length) return [3 /*break*/, 5];
                            _b = 0, _c = race.selectedSampling;
                            _d.label = 2;
                        case 2:
                            if (!(_b < _c.length)) return [3 /*break*/, 5];
                            ss = _c[_b];
                            return [4 /*yield*/, execRequest(this.modalActionsService.getSamplingRequest(ss.samplingId))];
                        case 3:
                            res = _d.sent();
                            ss.sampleRequestReasons = res.reasons;
                            _d.label = 4;
                        case 4:
                            _b++;
                            return [3 /*break*/, 2];
                        case 5:
                            racesCompleted++;
                            _d.label = 6;
                        case 6:
                            _i++;
                            return [3 /*break*/, 1];
                        case 7:
                            if (racesCompleted === this.races.length) {
                                resolve();
                            }
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        this.doPrint = function () {
            _this.meta.printMode = true;
            setTimeout(function () {
                window.print();
            }, 500);
            window.onafterprint = function () { return _this.meta.printMode = false; };
        };
    }
    FixtureReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.meta = {
            loading: false,
            printMode: false,
            status: 'initializing',
        };
        this.races = [];
        this.fixture = {
            fixtureId: Number(this.route.snapshot.paramMap.get('fixtureId')),
            fixtureYear: this.route.snapshot.paramMap.get('fixtureYear')
        };
        this.route.params.subscribe(function (params) {
            if (Object.keys(params).length) {
                _this.meta.loading = true;
                _this.getFixtureInfo(_this.fixture.fixtureYear, _this.fixture.fixtureId);
            }
        });
    };
    return FixtureReportComponent;
}());
export { FixtureReportComponent };
function execRequest(request) {
    return new Promise(function (resolve, reject) {
        request.subscribe(function (res) { return resolve(res); }, function (err) { return reject(err); });
    });
}
function groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
}
