import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';

@Injectable()
export class HermesService {

  constructor() { }

  private socket;

  updates() {
    const observable = new Observable(observer => {
      console.log('[ DEBUG ] Subscribed to Hermes feed');
      this.socket = io(environment.socketBase);

      this.socket.on(`update:non-runner`, (data) => {
        data = JSON.parse(data);
        data.type = 'nonRunner';
        observer.next(data);
      });

      this.socket.on(`update:withdrawn`, (data) => {
        data = JSON.parse(data);
        data.type = 'withdrawn';
        observer.next(data);
      });

      this.socket.on(`update:jockey-change`, (data) => {
        data = JSON.parse(data);
        data.type = 'jockeyChange';
        observer.next(data);
      });

      this.socket.on(`update:finishing-positions`, (data) => {
        data = JSON.parse(data);
        data.type = 'finishingPositions';
        observer.next(data);
      });

      return () => {
        console.log('[ DEBUG ] Disconnected from Hermes feed');
        this.socket.disconnect();
      };
    });
    return observable;
  }

}
