import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { forkJoin } from 'rxjs';

import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { FixturesService } from '../../../services/fixtures.service';
import { RacesService } from '../../../services/races.service';
import { EntriesService } from '../../../services/entries.service';
import { ModalActionsService } from '../../../services/modalActions.service';

@Component({
  selector: 'app-fixture-report',
  templateUrl: './fixture-report.component.html',
  styleUrls: ['./fixture-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FixtureReportComponent implements OnInit {

  meta: {
    loading: boolean,
    printMode: boolean,
    status: string,
  };
  fixture: any;
  races: any[];


  constructor(
    private notification: ToastrService,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private fixturesService: FixturesService,
    private racesService: RacesService,
    private entriesService: EntriesService,
    private modalActionsService: ModalActionsService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      printMode: false,
      status: 'initializing',
    };
    this.races = [];

    this.fixture = {
      fixtureId: Number(this.route.snapshot.paramMap.get('fixtureId')),
      fixtureYear: this.route.snapshot.paramMap.get('fixtureYear')
    };

    this.route.params.subscribe(params => {
      if (Object.keys(params).length) {
        this.meta.loading = true;
        this.getFixtureInfo(this.fixture.fixtureYear, this.fixture.fixtureId);
      }
    });
  }

  getFixtureInfo = (fixtureYear, fixtureId) => {
    this.meta.status = 'Loading Fixture Info';
    forkJoin(
      this.fixturesService.getFixtureInfo({ fixtureYear, fixtureId }),
      this.fixturesService.getFixtureComments({ fixtureYear, fixtureId })
    ).subscribe(
      res => {
        this.fixture = res[0];
        this.fixture.fixtureYear = this.fixture.year;
        this.eventsService.changeFixtureData(this.fixture);
        this.fixture.scheduledOfficials = groupBy(this.fixture.scheduledOfficials, 'role');
        this.fixture.comments = res[1]['data'];
        this.getFixtureRaces(this.fixture.fixtureYear, this.fixture.fixtureId);
      },
      err => {
        this.notification.error(`Could't get the Fixture information: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  getFixtureRaces = (fixtureYear, fixtureId): void => {
    this.meta.status = 'Loading Fixture\'s Races';
    this.fixturesService.getFixtureRaces({ fixtureYear, fixtureId }).subscribe(
      res => {
        this.races = res;
        forkJoin(
          this.getRaceInfo(this.races),
          this.getRaceEntries(this.races),
          this.getRaceComments(this.races),
          this.getRaceEnquiries(this.races),
        ).subscribe(
          () => this.processRaceInfo(),
          err => console.log(err),
        );
      },
      err => {
        this.notification.error(`Could't get the Fixture Entries with Sampling Requests: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.loading = false;
      }
    );
  }

  getRaceInfo = (races) => {
    return new Promise((resolve, reject) => {
      const raceInfoRequests = races.map(race => this.racesService.getRaceInfo(race));
      forkJoin(...raceInfoRequests).subscribe(
        results => {
          results.forEach((raceInfo, index) => {
            if (!raceInfo || !raceInfo['fixtureId']) {
              console.log('Failed to load data:', races[index].raceId, races[index]);
              this.notification.error(`Could't get race info for the ${moment(races[index].raceDateTime).format('HH:mm')} race.`, 'Error!');
              this.races[index].info = {};
            } else {
              this.races[index].info = raceInfo;
            }
          });
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get info for races.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  getRaceEntries = (races) => {
    return new Promise( (resolve, reject) => {
      const racesCompleted = 0;
      const entriesRequests = races.map(race => this.racesService.getRaceEntries(race));
      forkJoin(...entriesRequests).subscribe(
        async results => {
          results.forEach((entries: [any], indexRace) => {
            if (!entries || !entries[0]) {
              console.log('Failed to load data:', races[indexRace].raceId, races[indexRace]);
              this.notification.error(`Could't get some details for the ` +
              `${moment(races[indexRace].raceDateTime).format('HH:mm')} race.`, 'Error!');
              this.races[indexRace].entries = [];
            } else {
              this.races[indexRace].entries = entries;
            }
          });
          await this.getEntriesComments(this.races);
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get some details for racecards.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  getRaceComments = (races) => {
    return new Promise((resolve, reject) => {
      const raceCommentsRequests = races.map(race => this.racesService.getRaceComments(race));
      forkJoin(...raceCommentsRequests).subscribe(
        results => {
          results.forEach((raceComments, index) => {
            if (!raceComments['data']) {
              console.log('Failed to load data:', races[index].raceId, races[index]);
              this.notification.error(`Could't get race comments for the ` +
              `${moment(races[index].raceDateTime).format('HH:mm')} race.`, 'Error!');
              this.races[index].comments = [];
            } else {
              this.races[index].comments = raceComments['data'];
            }
          });
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get comments for races.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  getEntriesComments = (races) => {
    this.meta.status = 'Loading Horses Comments';
    let horsesCommentsRequests = [];
    return new Promise((resolve, reject) => {
      races.forEach((race, raceIndex) => {
        race.horsesComments = [];
        horsesCommentsRequests = horsesCommentsRequests
          .concat(race.entries
              .map(entry => this.entriesService
                  .getEntryTodayComments(entry.horseId,
                    false,
                    moment(entry.raceDateTime).format('YYYYMMDD'),
                    moment(entry.raceDateTime).format('YYYYMMDD')
                  )
              )
          );
      });
      forkJoin(...horsesCommentsRequests).subscribe(
        results => {
          results.forEach((comments, index) => {
            if (!comments['data']) {
              console.log('Failed to load data:', races[index].raceId, races[index]);
              this.notification.error(`Could't get horses comments.`, 'Error!');
              this.races[index].horsesComments = [];
            } else {
              comments['data'].forEach(comment => {
                const raceIndex = this.races.findIndex(r => r.raceId === comment.raceId && r.divisionSequence === comment.divisionSequence);
                if (raceIndex === -1) {
                  console.log('[ DEBUG ] RACE NOT FOUND', 'comment data', comment,
                    'current fixture', this.fixture.fixtureId,
                    'fixture\'s races:', this.races
                  );
                }
                // Weirdly enough, there could be horses running in different fixtures on the same day apparently
                if (raceIndex > -1) {
                  this.races[raceIndex].horsesComments.push(comment);
                }
              });
            }
          });
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get horses comments for one or more races.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  getRaceEnquiries = (races) => {
    return new Promise((resolve, reject) => {
      const raceEnquiriesRequests = races.map(race => this.racesService.getRaceEnquiries(race));
      forkJoin(...raceEnquiriesRequests).subscribe(
        results => {
          results.forEach((raceEnquiries, index) => {
            if (!raceEnquiries['data']) {
              console.log('Failed to load data:', races[index].raceId, races[index]);
              this.notification.error(`Could't get race enquiries for the ` +
              `${moment(races[index].raceDateTime).format('HH:mm')} race.`, 'Error!');
              this.races[index].enquiries = [];
            } else {
              raceEnquiries['data'].forEach(enquiry => {
                const breachStatus = enquiry.outcomes.find(eo => eo.objectType === 'inBreach');
                if (breachStatus !== undefined && breachStatus.isApplied) {
                  enquiry.breachStatus = breachStatus.value.replace(/_/g, ' ');
                } else {
                  enquiry.breachStatus = 'not in breach';
                }
              });
              this.races[index].enquiries = raceEnquiries['data'];
            }
          });
          resolve();
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.error(`Could't get enquiries for races.`, 'Error!');
          this.meta.loading = false;
          reject(errors);
        }
      );
    });
  }

  processRaceInfo = () => {
    this.meta.status = 'Processing...';
    this.races.forEach((race, raceIndex) => {
      race.nonRunners = race.entries.filter(e => e.nonRunnerDeclaredDate);
      race.withdrawals = race.entries.filter(e => e.indicators.isWithdrawn);
      race.jockeyChanged = race.entries.filter(e => e.indicators.hasJockeyChange);
      race.selectedSampling = race.entries.filter(e => e.indicators.hasBeenSelectedForSampling);
    });
    this.getAdditionalInfo();
  }

  getAdditionalInfo = () => {
    this.meta.status = 'Loading additional info';
    forkJoin(
      this.getAdditionalInfoNonRunners(),
      this.getAdditionalInfoWithdrawals(),
      this.getAdditionalInfoJockeyChanged(),
      this.getAdditionalInfoSelectedSampling()
    ).subscribe(
      res => {
        console.log('[ DEBUG ] Finished', 'Fixture', this.fixture, 'Races/Entries', this.races);
        this.meta.status = 'Finalising...';
        setTimeout(() => this.meta.loading = false, 1000);
      },
      err => console.log(err),
    );
  }

  getAdditionalInfoNonRunners = () => {
    return new Promise(async (resolve, reject) => {
      let racesCompleted = 0;
      for (const race of this.races) {
        if (race.nonRunners.length) {
          for (const nr of race.nonRunners) {
            const res = await execRequest(this.modalActionsService.getNonRunnerInfo(nr));
            nr.stipesReason = res.reasonText;
            nr.stipesComment = res.comment;
            nr.stipesInternalComment = res.internalComment;
          }
        }
        racesCompleted++;
      }
      if (racesCompleted === this.races.length) {
        resolve();
      }
    });
  }

  getAdditionalInfoWithdrawals = () => {
    return new Promise(async (resolve, reject) => {
      let racesCompleted = 0;
      for (const race of this.races) {
        if (race.withdrawals.length) {
          for (const w of race.withdrawals) {
            const res = await execRequest(this.modalActionsService.getWithdrawnInfo(w));
            w.feePaid = res.feePaid;
            w.stipesReason = res.reasonText;
            w.stipesComment = res.comment;
            w.stipesInternalComment = res.stipesInternalComment;
          }
        }
        racesCompleted++;
      }
      if (racesCompleted === this.races.length) {
        resolve();
      }
    });
  }

  getAdditionalInfoJockeyChanged = () => {
    return new Promise(async (resolve, reject) => {
      let racesCompleted = 0;
      for (const race of this.races) {
        if (race.jockeyChanged.length) {
          for (const jockey of race.jockeyChanged) {
            const latestChange = jockey.jockeyChangeHistory[jockey.jockeyChangeHistory.length - 1];
            jockey.oldJockeyName = latestChange.oldJockeyKnownAs;
            jockey.newJockeyName = latestChange.newJockeyKnownAs;
            jockey.stipesReason = latestChange.comment;
          }
        }
        racesCompleted++;
      }
      if (racesCompleted === this.races.length) {
        resolve();
      }
    });
  }

  getAdditionalInfoSelectedSampling = () => {
    return new Promise(async (resolve, reject) => {
      let racesCompleted = 0;
      for (const race of this.races) {
        if (race.selectedSampling.length) {
          for (const ss of race.selectedSampling) {
            const res = await execRequest(this.modalActionsService.getSamplingRequest(ss.samplingId));
            ss.sampleRequestReasons = res.reasons;
          }
        }
        racesCompleted++;
      }
      if (racesCompleted === this.races.length) {
        resolve();
      }
    });
  }

  doPrint = () => {
    this.meta.printMode = true;
    setTimeout(() => {
      window.print();
    }, 500);
    window.onafterprint = () => this.meta.printMode = false;
  }

}

function execRequest(request): any {
  return new Promise((resolve, reject) => {
    request.subscribe(
      res => resolve(res),
      err => reject(err)
    );
  });
}

function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
