import {Component, OnInit, Input} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../../services/events.service';
import { ModalActionsService } from '../../../../services/modalActions.service';
import * as moment from 'moment';

@Component({
  selector: 'app-race-left-parade-ring',
  templateUrl: './race-left-parade-ring.component.html',
  styleUrls: ['./race-left-parade-ring.component.scss'],
})
export class RaceLeftParadeRingComponent implements OnInit {
  @Input() race;
  @Input() paradeRingRaces;
  meta: {
    loading: boolean,
    saving: boolean
  };
  
  leftParadeRingTime: string;
  userId: number;
  action: string;
  seconds: true;
  time = {hour: null, minute: null, second: null};
  onTimeChange() {
    const leftParadeRingTime = this.time;
    this.leftParadeRingTime = moment(leftParadeRingTime).format('HH:mm:ss');
    // console.log(this.leftParadeRingTime);
  }
  
  constructor(
    private activeModal: NgbActiveModal,
    private notification: ToastrService,
    private eventsService: EventsService,
    private modalActionsService: ModalActionsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false
    };
    this.eventsService.observableUser.subscribe(data => {
      this.userId = data.id;
    });
    this.getLeftParadeRingRaces();
  }
  getLeftParadeRingRaces = () => {
    this.meta.loading = true;
    this.modalActionsService.getLeftParadeRingRaces(this.race).subscribe(
      res => {
        this.paradeRingRaces = res;
        console.log(this.paradeRingRaces);
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Couldn't  ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
      }
    );
  }
  confirm = () => {
    this.meta.saving = true;
    const payload: any = {
      leftParadeRingTime: this.leftParadeRingTime,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.updateLeftParadeRingRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} added as parade ring time`, 'Success!');
        this.meta.saving = true;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.saving = false;
      }
    );
  }
  deleteRace = () => {
    this.meta.loading = true;
    const payload: any = {
      leftParadeRingTime: this.leftParadeRingTime,
      action: this.action,
      userId: this.userId,
    };
    this.modalActionsService.removeParadeRingRace(this.race, payload).subscribe(
      res => {
        this.activeModal.close(res);
        this.notification.success(`${this.race.raceName} removed as a parade ring time`, 'Success!');
        this.meta.loading = false;
      },
      err => {
        this.notification.error(`Something went wrong ` +
          `${this.eventsService.getApiErrorMessage(err)}`, 'Error!', { disableTimeOut: true });
        this.meta.loading = false;
      }
    );
  }
  cancel = (action) => {
    this.activeModal.dismiss(action);
  }
}
