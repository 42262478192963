import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'racetime'
})
export class RaceTimePipe implements PipeTransform {

  transform(value:any, args:any){

    return moment(value,'HH:mm:ss').format("h:mm a");

  }

}
