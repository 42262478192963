import { Component, OnInit, Input } from '@angular/core';

import { forkJoin ,  of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as moment from 'moment';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventsService } from '../../../services/events.service';
import { ModalActionsService } from '../../../services/modalActions.service';

import { EntryNonRunnerComponent } from '../../modals/actions/entry-non-runner/entry-non-runner.component';
import { EntryWithdrawnComponent } from '../../modals/actions/entry-withdrawn/entry-withdrawn.component';
import { EntryJockeyChangeComponent } from '../../modals/actions/entry-jockey-change/entry-jockey-change.component';

@Component({
  selector: 'app-interactive-events',
  templateUrl: './interactive-events.component.html',
  styleUrls: ['./interactive-events.component.scss']
})
export class InteractiveEventsComponent implements OnInit {

  meta: {
    loading: boolean,
    saving: boolean,
    activeIds: Array<String>,
    raceTodoList: {
      loading: boolean,
      saving: boolean,
      failed: boolean,
    }
  };
  @Input() selectedRace: any;
  @Input() selectedFixture: any;

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private modalActions: ModalActionsService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      loading: false,
      saving: false,
      activeIds: [],
      raceTodoList: {
        loading: false,
        saving: false,
        failed: false,
      }
    };

    this.eventsService.observableRacecard.subscribe(
      payload => {
        // console.log('triggered from racecard changes');
        this.unpdateTodoList(payload);
      },
      error => console.log(error),
      () => console.log('done')
    );

    this.getRaceTodolist(this.selectedRace);
  }

  getRaceTodolist = (race) => {
    this.meta.raceTodoList.loading = true;
    this.selectedRace.todoList = {
      withdrawn: this.selectedRace.entries.filter(e => e.indicators.isWithdrawn),
      jockeyChange: this.selectedRace.entries.filter(e => e.indicators.hasJockeyChange),
      nonRunner: this.selectedRace.entries.filter(e => e.nonRunnerDeclaredDate),
      finishingPositions: this.selectedRace.entries.filter(e => e.finishingPositions)
    };
    this.selectedRace.todoList.totals = (
      this.selectedRace.todoList.withdrawn.length
      // + this.selectedRace.todoList.jockeyChange.length
      + this.selectedRace.todoList.nonRunner.length
    ) || 0;

    // Resolve Jockey Changes
    if (this.selectedRace.todoList['jockeyChange'].length) {
      for (let i = 0; i < this.selectedRace.todoList['jockeyChange'].length; i++) {
        this.selectedRace.todoList['jockeyChange'][i] = Object.assign({},
          this.selectedRace.todoList['jockeyChange'][i],
          this.selectedRace.todoList['jockeyChange'][i].jockeyChangeHistory[
            this.selectedRace.todoList['jockeyChange'][i].jockeyChangeHistory.length - 1
          ]
        );
      }
    }

    // Get individual additional data for each entry, if there is at least one
    const entryInfoRequests = [];
    if (this.selectedRace.todoList.totals) {
      Object.keys(this.selectedRace.todoList).forEach(context => {
        if (this.selectedRace.todoList[context].length && context !== 'jockeyChange') {
          this.selectedRace.todoList[context].forEach(entry => {
            const funcName = 'get' + context.charAt(0).toUpperCase() + context.slice(1) + 'Info';
            entryInfoRequests.push( this.modalActions[funcName](entry).pipe(catchError(err => of(err))) );
          });
        }
      });
      forkJoin(...entryInfoRequests).subscribe(
        results => {
          results.forEach(res => {
            if (res['horseId'] && res['context']) {
              const entry = this.selectedRace.todoList[res['context']].find(targetEntry => targetEntry.horseId === res['horseId']);
              entry.reasonText = res['reasonText'];
              entry.comment = res['comment'];
              entry.internalComment = res['internalComment'];
            } else {
              // this.notification.warning(`Could't get some details for entries. ` +
              // `You can still work but the 'Additional Information' section will not be reliable unless reloaded.`, 'Warning!');
              this.meta.raceTodoList.failed = true;
            }
            this.meta.raceTodoList.loading = false;
          });
          this.selectedRace.todoList.totals += this.selectedRace.todoList.jockeyChange.length;
        },
        errors => {
          console.log('Failed to load data:', errors);
          this.notification.warning(`Could't get some details for entries. ` +
          `You can still work but the 'Additional Information' section will ` +
          `not be reliable unless reloaded.`, 'Error!', { disableTimeOut: true });
          this.meta.raceTodoList.failed = true;
          this.meta.raceTodoList.loading = false;
        }
      );
      // this.updateAccordionStatus();
    } else {
      this.selectedRace.todoList.totals += this.selectedRace.todoList.jockeyChange.length;
      // this.updateAccordionStatus();
      this.meta.raceTodoList.loading = false;
    }
  }

  unpdateTodoList = (payload) => {
    if (payload.section === 'todoList') {
      if (this.selectedRace.todoList && payload.context) {
        console.log('todoList update received', payload, this.selectedRace.todoList);
        if (payload.result.year === this.selectedRace.raceYear &&
            payload.result.raceId === this.selectedRace.raceId &&
            payload.result.divisionSequence === this.selectedRace.divisionSequence) {
          const targetListEntry = this.selectedRace.todoList[payload.context]
            .find(existingEntry => existingEntry.horseId === payload.result.horseId);
          // console.log('payload >>>', payload);
          // console.log('context >>>', this.selectedRace.todoList
          //  ? this.selectedRace.todoList[payload.context]
          //  : this.selectedRace.todoList);
          // console.log('targetListEntry >>>', targetListEntry);
          if (targetListEntry !== undefined) {
            switch (payload.context) {
              // jockeyChange
              case 'jockeyChange': targetListEntry.comment = payload.result.comment; break;
              // withdrawn and nonRunner
              default: {
                if (payload.context === 'nonRunner') {
                  // targetListEntry.nonRunnerDeclaredDate = moment(payload.result.declaredAt).format('YYYY-MM-DD');
                  // targetListEntry.nonRunnerDeclaredTime = moment(payload.result.declaredAt).format('HH:mm:ss');
                  targetListEntry.comment = payload.result.comment;
                  targetListEntry.internalComment = payload.result.internalComment;
                }
                if (payload.context === 'withdrawn') {
                  if (payload.result.indicators && payload.result.indicators.isWithdrawn === false) {
                    const indexToRemove = this.selectedRace.todoList[payload.context]
                      .findIndex(entry => entry.horseId === payload.result.horseId);
                      this.selectedRace.todoList[payload.context].splice(indexToRemove, 1);
                      this.selectedRace.todoList.totals -= 1;
                      this.updateAccordionStatus();
                  }
                }
                targetListEntry.reasonText = payload.result.reasonText;
              }
            }
          } else {
            if (payload.context !== 'withdrawn' ||
            (payload.context === 'withdrawn' && payload.result.indicators &&  payload.result.indicators.isWithdrawn === true)) {
              this.selectedRace.todoList[payload.context].push(
                Object.assign({}, { horseName: payload.result.horseName }, payload.result)
              );
              this.selectedRace.todoList.totals += 1;
              this.updateAccordionStatus();
            }
          }
        }
      }
    }
  }

  updateAccordionStatus = () => {
    if (this.selectedRace.todoList.totals) {
      this.meta.activeIds = ['todoList'];
    } else {
      this.meta.activeIds = [];
    }
  }

  openJockeyChange = (entry) => {
    const modalWindow = this.modalService.open(EntryJockeyChangeComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] model closed with result:`, result);
      entry.comment = result.comment;
      entry.jockeyChangeHistory[entry.jockeyChangeHistory.length - 1].comment = result.comment;
      entry.jockeyChangeHistory[entry.jockeyChangeHistory.length - 1].id = result.id;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openNonRunner = (entry) => {
    const modalWindow = this.modalService.open(EntryNonRunnerComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] model closed with result:`, result);
      entry.reasonText = result.reasonText;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openWithdrawn = (entry) => {
    const modalWindow = this.modalService.open(EntryWithdrawnComponent,
      { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] model closed with result:`, result);
      entry.reasonText = result.reasonText;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

}
