import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../services/events.service';

import { environment } from '../../../environments/environment';

import { FixtureUpdateOfficialsComponent } from '../modals/actions/fixture-update-officials/fixture-update-officials.component';
import { AddEditCommentComponent } from '../modals/actions/add-edit-comment/add-edit-comment.component';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    private eventsService: EventsService,
    private authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  isLoggedIn: Object|Boolean = false;
  selectedFixture: any;
  externalLinks: Array<{name: String, url: String}>;

  ngOnInit() {
    this.eventsService.observableFixture.subscribe(data => {
      // console.log('navbar received fixture data', data);
      this.selectedFixture = data;
      this.externalLinks = [
        {name: 'Rico', url: `${environment.ricoBase}/${this.selectedFixture.fixtureDate}/0`},
        {name: 'Racing Admin', url: 'https://www2.racingadmin.co.uk/JGNS031/communicationhub.html'},
        {name: 'Raceday Team Briefing Document', url: 'https://www.cognitoforms.com/BritishHorseracingAuthority/BHARacedayTeamBriefing'},
        {name: 'Abandonment decision form', url: 'https://www.cognitoforms.com/BritishHorseracingAuthority/abandonmentdecisionform'},
        {name: 'Whip Review Committee Referral Form', url: 'https://www.cognitoforms.com/BritishHorseracingAuthority/whipreviewcommitteereferralform'},
        {name: 'Medical Compliance', url: 'https://www.cognitoforms.com/britishhorseracingauthority/bhagi112annexgmedicalcompliance'}, 
      ];
    });

    this.eventsService.observableUser.subscribe(data => {
      if (data && data.id) {
        this.isLoggedIn = true;
      }
    });
  }

  openExternalLink(url) {
    window.open(url, '_blank');
  }

  resetFixture = (): void => {
    this.eventsService.changeFixtureData({courseName: null});
    this.router.navigate(['fixtures']);
  }
  logout() {
    this.authService.destroySession();
    this.router.navigate(['/login']);
  }

}
