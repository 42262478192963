import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { EntriesService } from '../../../services/entries.service';
import { EventsService } from '../../../services/events.service';

import { AddEditCommentComponent } from '../../modals/actions/add-edit-comment/add-edit-comment.component';
import { AddEditEnquiryComponent } from '../../modals/actions/add-edit-enquiry/add-edit-enquiry.component';
import { EntrySamplingRequestComponent } from '../../modals/actions/entry-sampling-request/entry-sampling-request.component';
import { EntryNonRunnerComponent } from '../../modals/actions/entry-non-runner/entry-non-runner.component';
import { EntryWithdrawnComponent } from '../../modals/actions/entry-withdrawn/entry-withdrawn.component';
import { EntryJockeyChangeComponent } from '../../modals/actions/entry-jockey-change/entry-jockey-change.component';
import { JockeyChangeHistoryComponent } from '../../modals/jockey-change-history/jockey-change-history.component';

@Component({
  selector: 'app-race-entry',
  templateUrl: './race-entry.component.html',
  styleUrls: ['./race-entry.component.scss']
})
export class RaceEntryComponent implements OnInit {

  @Input() entry = null;
  @Input() actions = {
    none: false,
    enquiry: true,
    comment: true,
    sampling: true,
    jockeyChange: true,
    jockeyChangeHistory: true,
    nonRunner: true,
    withdrawn: true,
  };
  @Input() even = false;
  @Input() odd = false;
  @Input() first = false;
  @Input() last = false;
  @Input() borderTop = false;
  @Input() borderBottom = false;
  meta: {
    raceDate: string,
    loading: boolean,
    saving: boolean,
    previousRpcComment: {
      loading: boolean,
      saving: boolean
    },
    todayComments: {
      loading: boolean,
      saving: boolean
    },
    previousEnquiry: {
      loading: boolean,
      saving: boolean
    }
  };
  enabledActions: {
    none: boolean,
    enquiry: boolean,
    comment: boolean,
    sampling: boolean,
    jockeyChange: boolean,
    jockeyChangeHistory: boolean,
    nonRunner: boolean,
    withdrawn: boolean,
  };

  constructor(
    private modalService: NgbModal,
    private notification: ToastrService,
    private entriesService: EntriesService,
    private eventsService: EventsService
  ) { }

  ngOnInit() {
    this.meta = {
      raceDate: moment(this.entry.raceDateTime).format('YYYYMMDD'),
      loading: false,
      saving: false,
      previousRpcComment: {
        loading: false,
        saving: false
      },
      todayComments: {
        loading: false,
        saving: false
      },
      previousEnquiry: {
        loading: false,
        saving: false
      }
    };

    this.entry.shortDispensation = this.entry.dispensation
      ? this.entry.shoesDispensation.dispensation.split(' ').splice(0, 4).join(' ')
      : null;

    if (this.actions && Object.keys(this.actions).length) {
      this.enabledActions = this.actions;
    }

    this.loadOldComments(this.entry.horseId);
    this.loadOldEnquiries(this.entry.horseId);
    this.loadTodayComments(this.entry.horseId);

    // console.log('Entry:', this.entry);
  }

  debugShowMe(entry) {
    console.log('Clicked on entry:', entry);
  }

  loadOldComments = (horseId) => {
    this.meta.previousRpcComment.loading = true;
    this.entriesService.getEntryOldComments(horseId, true, moment(this.meta.raceDate).subtract(1, 'days').format('YYYYMMDD')).subscribe(
      res => {
        if (res.data.length) {
          this.entry.mostRecentRPCText = res.data[0].rpcComments.map(rpcComment => rpcComment.racePerformanceComment).join(', ');
          this.entry.mostRecentRPCDate = res.data[0].updatedAt;
        }
        this.meta.previousRpcComment.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Entry most recent RPC: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.previousRpcComment.loading = false;
      }
    );
  }

  loadOldEnquiries = (horseId) => {
    this.meta.previousEnquiry.loading = true;
    this.entriesService.getEntryOldEnquiries(horseId, moment(this.meta.raceDate).subtract(1, 'days').format('YYYYMMDD')).subscribe(
      res => {
        if (res.data.length) {
          this.entry.mostRecentEnquiryText = res.data[0].offence.offence;
          this.entry.mostRecentEnquiryDate = res.data[0].date;
        }
        this.meta.previousEnquiry.loading = false;
      },
      err => {
        this.notification.error(`Could't get the Entry most recent Enquiry: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.previousEnquiry.loading = false;
      }
    );
  }

  loadTodayComments = (horseId) => {
    this.meta.todayComments.loading = true;
    const todayCommentsRequest = this.entriesService.getEntryTodayComments(horseId, false,
      moment(this.meta.raceDate).format('YYYYMMDD'),
      moment(this.meta.raceDate).format('YYYYMMDD'));
      todayCommentsRequest.subscribe(
      results => {
        // separate rcp comments from the non-rpc
        this.entry.rpcComments = results['data'].filter(c => c.commentType === 'rpc');
        this.entry.comments = results['data'].filter(c => c.commentType === 'other');

        // Process RPCs
        if (this.entry.rpcComments.length) {
          this.entry.todayCommentText = this.entry.rpcComments[0]
            .rpcComments.map(rpcComment => rpcComment.racePerformanceComment).join(', ');
          this.entry.todayCommentDate = this.entry.rpcComments[0].updatedAt;
          this.entry.todayCommentsCount = this.entry.rpcComments.length;
          this.entry.rpcComments.forEach(horseRpc => {
            this.eventsService.changeCommentsData({section: 'horseComments', result: horseRpc});
          });
        }

        // Process non-RPCs
        if (this.entry.comments.length) {
          this.entry.comments.forEach(horseComment => {
            this.eventsService.changeCommentsData({section: 'horseComments', result: horseComment});
          });
        }
        this.meta.todayComments.loading = false;
      },
      err => {
        this.notification.error(`Could't get ${this.entry.horseName} comments: ${this.eventsService.getApiErrorMessage(err)}`, 'Error!');
        this.meta.todayComments.loading = false;
      }
    );
  }

  openAddEnquiry = (entry) => {
    const possibleOffenders = [
      {
        id: entry.horseId,
        knownAs: entry.horseName,
        type: 'horse'
      },
      {
        id: entry.jockeyId,
        knownAs: entry.jockeyKnownAs,
        type: 'jockey',
        jockeyMasterId: entry.jockeyMasterId,
        jockeyMasterName: entry.jockeyMasterName,
        jockeyMasterInvolved: false
      },
      {
        id: entry.trainerId,
        knownAs: entry.trainerKnownAs,
        type: 'trainer'
      },
      {
        id: entry.ownerId,
        knownAs: entry.ownerKnownAs,
        type: 'owner'
      }
    ];
    const enquiry = {
      meta: { year: entry.year, raceId: entry.raceId, divisionSequence: entry.divisionSequence, horseId: entry.horseId },
      offender: possibleOffenders[1] // jockey by default as it's the most common
    };
    const modalWindow = this.modalService
    .open(AddEditEnquiryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.enquiry = enquiry;
    modalWindow.componentInstance.possibleOffenders = possibleOffenders;
    modalWindow.result.then((result) => {
      this.entry.mostRecentEnquiryText = result.offence.offence;
      this.entry.mostRecentEnquiryDate = result.date;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openAddComment = (entry) => {
    const possibleSubjects = [
      {
        id: entry.horseId,
        knownAs: entry.horseName,
        type: 'horse'
      },
      {
        id: entry.jockeyId,
        knownAs: entry.jockeyKnownAs,
        type: 'jockey'
      },
      {
        id: entry.trainerId,
        knownAs: entry.trainerKnownAs,
        type: 'trainer'
      },
      {
        id: entry.ownerId,
        knownAs: entry.ownerKnownAs,
        type: 'owner'
      }
    ];
    const comment = {
      subject: possibleSubjects[0],
      entry: {
        year: entry.year,
        raceId: entry.raceId,
        divisionSequence: entry.divisionSequence,
        horseId: entry.horseId
      }
    };
    const modalWindow = this.modalService.open(AddEditCommentComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.comment = comment;
    modalWindow.componentInstance.possibleSubjects = possibleSubjects;
    modalWindow.result.then((result) => {
      if (result.rpcComments && result.rpcComments.length) {
        this.entry.todayCommentText = result.rpcComments.map(rpcComment => rpcComment.racePerformanceComment).join(', ');
        this.entry.todayCommentDate = result.updatedAt;
        this.entry.todayCommentsCount = this.entry.todayCommentsCount ? this.entry.todayCommentsCount + 1 : 1;
      }
      this.eventsService.changeCommentsData({section: 'horseComments', result: result});
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openRequestSampling = (entry) => {
    const modalWindow = this.modalService.open(EntrySamplingRequestComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      if (result.horseId) {
        this.entry.indicators.hasBeenSelectedForSampling = true;
        this.entry.samplingId = result.samplingId;
      } else {
        this.entry.indicators.hasBeenSelectedForSampling = false;
        this.entry.samplingId = null;
      }
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openChangeJockey = (entry) => {
    const modalWindow = this.modalService.open(EntryJockeyChangeComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      console.log(`[ DEBUG ] Modal returned value:`, result);
      const last = entry.jockeyChangeHistory.length - 1;
      entry.jockeyChangeHistory[last].comment = result.comment;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openChangeJockeyHistory = (entry) => {
    const modalWindow = this.modalService.open(JockeyChangeHistoryComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal returned value:`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openNonRunner = (entry) => {
    const modalWindow = this.modalService.open(EntryNonRunnerComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal returned value:`, result);
      this.entry.nonRunnerDeclaredDate = moment(result.declaredAt).format('YYYY-MM-DD');
      this.entry.nonRunnerDeclaredTime = moment(result.declaredAt).format('HH:mm:ss');
      this.entry.nonRunnerDeclaredReason = result.reasonText;
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

  openWithdrawn = (entry) => {
    const modalWindow = this.modalService.open(EntryWithdrawnComponent, { centered: true, backdrop: 'static', size: 'lg'});
    modalWindow.componentInstance.entry = entry;
    modalWindow.result.then((result) => {
      // console.log(`[ DEBUG ] Modal returned value:`, result);
    }, (reason) => {
      // console.log(`[ DEBUG ] Modal dismissed: ${reason}`);
    });
  }

}
